// src/components/ProductPage.js
import React from 'react';
import './ProductPage.css';

const productDetails = [
  {
    type: "E-commerce Website",
    description:
      "Build your own online store with a user-friendly interface, secure payment gateways, and product management features.",
    startingPrice: "₹10,000",
    features: [
      "Customizable product pages",
      "Payment gateway integration",
      "Shopping cart functionality",
      "Inventory management",
      "SEO optimization"
    ],
  },
  {
    type: "Corporate Website",
    description:
      "A professional website that represents your business with an elegant design, company information, and contact details.",
    startingPrice: "₹8,000",
    features: [
      "Responsive design",
      "Branding and logo integration",
      "Contact form and map integration",
      "Social media links",
      "Content management system"
    ],
  },
  {
    type: "Landing Page",
    description:
      "Create an impactful landing page that converts visitors with clear calls-to-action, lead generation forms, and more.",
    startingPrice: "₹5,000",
    features: [
      "High-converting design",
      "Lead capture forms",
      "SEO-friendly",
      "Clear CTAs",
      "Mobile responsive"
    ],
  },
  {
    type: "Portfolio Website",
    description:
      "Showcase your work in a visually stunning portfolio with easy-to-navigate galleries and interactive elements.",
    startingPrice: "₹6,000",
    features: [
      "Project galleries",
      "Client testimonials",
      "Responsive design",
      "Custom animations",
      "Content management system"
    ],
  },
  {
    type: "Blog Website",
    description:
      "Start sharing your ideas and articles with a simple, yet beautiful blog website that is easy to maintain.",
    startingPrice: "₹4,500",
    features: [
      "Post categories and tags",
      "Comment sections",
      "Content management system",
      "SEO optimization",
      "Social sharing options"
    ],
  },
];

function ProductPage() {
  return (
    <div className="product-page1">
      <h1>Website Development Services</h1>
      <p>We offer a variety of website development options tailored to your business needs. Check out our plans below:</p>

      <div className="product-cards-container1">
        {productDetails.map((product, index) => (
          <div key={index} className="product-card1">
            <h2>{product.type}</h2>
            <p className="description">{product.description}</p>
            <p className="price">
              Starting at: <span>{product.startingPrice}</span>
            </p>

            <h3>Features:</h3>
            <ul>
              {product.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            <button className="contact-btn">
              <a href="https://wa.me/6290529857?text=I%20want%20to%20order%20a%20website" target="_blank" rel="noopener noreferrer">
                Contact Us on WhatsApp
              </a>
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductPage;