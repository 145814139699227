import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa'; // Importing WhatsApp icon from react-icons
import Header from './components/Header';
import Banner from './components/Banner';
import MainComponent from './components/Work';
import Project from './components/Project';
import Testimonial from './components/Testimonial';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';
import About from './components/About';
import ProductPage from './components/Product';
import TechStackPage from './components/tech';
import './App.css';
import FAQ from './components/FAQ';
import CostPredictor from './components/CostPredictor';
import Loader from './components/Loader'; // Import the Loader component

function App() {
  const [loading, setLoading] = useState(true);

  // Simulate loading for 3 seconds or can replace with real loading condition
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <Router>
      <div className="App">
        {/* Show loader while loading */}
        {loading && <Loader />}

        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <MainComponent />
              <Project />
              <TechStackPage />
              <Testimonial />
              <Team />
              <FAQ />
              <Contact />
            </>
          } />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<MainComponent />} />
          <Route path="/portfolio" element={<><Project /><Testimonial /></>} />
          <Route path="/Product" element={<ProductPage />} />
          <Route path="/cost" element={<CostPredictor />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />

        {/* WhatsApp icon with rel="noreferrer" */}
        <a
          href="https://wa.me/6290529857"
          target="_blank"
          rel="noreferrer"
          className="whatsapp-icon"
        >
          <FaWhatsapp size={40} color="#ffffff" />
        </a>
      </div>
    </Router>
  );
}
export default App;