import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Banner.css';

function Banner() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the animation duration
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <section className="hero-section" data-aos="fade-up">
      <div className="tagline-carousel" data-aos="fade-right">
        <p>DhandaEasy: Streamlining Your Path to Stunning Websites!</p>
      </div>
      <h1 data-aos="fade-up" data-aos-delay="200">
        Unlock your Growth with <span data-aos="zoom-in" data-aos-delay="400">DhandaEasy</span>
      </h1>
      <p className="hero-subtext" data-aos="fade-in" data-aos-delay="600">
        Helping you grow with us using modern technologies
      </p>
      <button className="work-with-us" data-aos="fade-up" data-aos-delay="800">Work With Us</button>

      {/* New Button to Predict Website Cost */}
      <a href='/cost'>
      <button className="predict-cost-btn" data-aos="zoom-in" data-aos-delay="1000">
        Predict Website Cost
      </button>
    </a>

      <div className="social-icons" data-aos="fade-up" data-aos-delay="1200">
        <a href="https://wa.me/6290529857?text=I%20need%20to%20develop%20a%20website">
          <i className="fa fa-whatsapp"></i>
        </a>
      </div>
    </section>
  );
}

export default Banner;