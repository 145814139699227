// src/components/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">About DhandaEasy</h1>
      <p className="about-subtitle">
        At DhandaEasy, we believe in making high-quality, custom web solutions accessible to everyone. Our team focuses on affordable solutions with a professional touch.
      </p>

      <div className="about-section">
        <h2 className="about-heading">Our Mission</h2>
        <p className="about-text">
          Our mission is to empower businesses of all sizes with modern, user-friendly websites and digital tools that drive growth. Whether you're a startup or an established brand, we provide personalized, scalable solutions tailored to your needs.
        </p>

        <h2 className="about-heading">Why Choose Us?</h2>
        <p className="about-text">
          With DhandaEasy, you’re not just getting a website; you’re gaining a partner in growth. We prioritize:
        </p>
        <ul className="about-list">
          <li>🔹 High-Quality Design & Development</li>
          <li>🔹 SEO-Optimized Content</li>
          <li>🔹 Competitive Pricing</li>
          <li>🔹 Friendly, Personalized Service</li>
        </ul>

        <h2 className="about-heading">Our Services</h2>
        <p className="about-text">
          From web development and UI/UX design to digital marketing and app development, our full suite of services ensures you have everything needed to succeed online.
        </p>

        <a href="https://wa.me/6290529857?text=I%20need%20website%20development%20service" rel="noreferrer" className="about-contact-link">Get in Touch</a>
      </div>
    </div>
  );
};

export default About;