import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const faqs = [
    {
      question: "What services does DhandaEasy provide?",
      answer:
        "DhandaEasy offers website development, digital marketing, app development, SEO solutions, and startup mentorship. We specialize in creating modern, high-quality websites starting at just ₹5000.",
    },
    {
      question: "What makes DhandaEasy unique?",
      answer:
        "Our commitment to quality, affordability, and mentorship for startups sets us apart. We provide tailored solutions to help businesses grow in the digital space.",
    },
    {
      question: "What industries do you serve?",
      answer:
        "We work with startups, small businesses, and enterprises across industries such as retail, eCommerce, healthcare, education, and more.",
    },
    {
      question: "Do you provide mentorship for startups?",
      answer:
        "Yes! DhandaEasy offers startup mentorship to help new businesses succeed, guiding them through strategy, technology, and digital presence building.",
    },
    {
      question: "How much does website development cost?",
      answer:
        "Our website development services start at an affordable price of ₹5000, ensuring modern and professional designs for businesses of all sizes.",
    },
    {
      question: "Do you provide ongoing support after development?",
      answer:
        "Yes, we offer maintenance and support packages to ensure your website and apps remain up-to-date and functional.",
    },
    {
      question: "How can I contact DhandaEasy for inquiries?",
      answer:
        "You can contact us via email at dhandaeasy@gmail.com or call us at 6290529857. We are here to help you succeed!",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1 className="faq-title">Frequently Asked Questions</h1>
      <div className="faq-grid">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              {faq.question}
            </div>
            <div
              className={`faq-answer ${
                openIndex === index ? "faq-show" : "faq-hide"
              }`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;