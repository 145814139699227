import React, { useState, useEffect } from 'react';
import './Loader.css';  // Import the styled loader CSS

const Loader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay for loading (can be replaced by real data fetching)
    setTimeout(() => {
      setLoading(false);  // Set loading to false after 3 seconds
    }, 3000);
  }, []);

  if (!loading) {
    return null; // Don't render loader once loading is done
  }

  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;