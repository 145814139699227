// src/components/Header.js
import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="navbar">
      <div className="logo">
        <img src="\DhandaeASY (3)-Photoroom.png" alt="Company Logo" /> {/* Added alt attribute */}
      </div>
      <nav className="nav-links">
      <a href="/">Home</a>
      <a href="/about">About</a>
      <a href="/services">Services</a>
      <a href="/portfolio">Portfolio</a>
      <a href="/Product">Product</a>
      <a href="/cost">Cost Predictor</a>
      <a href="/contact">Contact Us</a>
    </nav>
      <a href="https://wa.me/6290529857?text=I%20need%20to%20develop%20a%20website" target="_blank" rel="noreferrer">
  <button className="get-started">Get Started</button>
</a>
    </header>
  );
}

export default Header;