import React, { useState } from "react";
import "./CostPredictor.css";

// Features and their prices
const allFeatures = [
  { name: "Custom UI Design", price: 3000 },
  { name: "Customizable product pages", price: 1500 },
  { name: "Payment gateway integration", price: 2000 },
  { name: "Shopping cart functionality", price: 1000 },
  { name: "Inventory management", price: 800 },
  { name: "SEO optimization", price: 1200 },
  { name: "Live chat integration", price: 1500 },
  { name: "Social media links", price: 600 },
  { name: "Analytics integration", price: 1000 },
  { name: "Content management system", price: 1500 },
  { name: "Responsive design", price: 1000 },
  { name: "Branding and logo integration", price: 1200 },
  { name: "Contact form and map integration", price: 800 },
];

// Website types and base prices
const websiteOptions = [
  { type: "E-commerce Website", basePrice: 10000 },
  { type: "Corporate Website", basePrice: 8000 },
  { type: "Personal Portfolio Website", basePrice: 4000 },
  { type: "Blog Website", basePrice: 3000 },
  { type: "Non-Profit Website", basePrice: 5000 },
  { type: "Educational Website", basePrice: 7000 },
  { type: "Restaurant Website", basePrice: 6000 },
  { type: "Event Website", basePrice: 5500 },
  { type: "Real Estate Website", basePrice: 9500 },
  { type: "News Website", basePrice: 8500 },
  { type: "Subscription-based Website", basePrice: 12000 },
  { type: "Social Media Website", basePrice: 15000 },
  { type: "Government Website", basePrice: 10000 },
  { type: "Job Portal Website", basePrice: 11000 },
  { type: "Dating Website", basePrice: 13000 },
  { type: "Portfolio for Artists", basePrice: 4500 },
  { type: "Photography Website", basePrice: 7000 },
  { type: "Fitness Website", basePrice: 6000 },
  { type: "Healthcare Website", basePrice: 9500 },
  { type: "Travel Website", basePrice: 7500 },
  { type: "Gaming Website", basePrice: 13000 },
  { type: "Music Website", basePrice: 8500 },
  { type: "Technology Website", basePrice: 9000 },
];

// Tech stack options
const techStackOptions = {
  frontend: [
    { name: "React", price: 2000 },
    { name: "Angular", price: 1800 },
    { name: "Vue.js", price: 1500 },
  ],
  backend: [
    { name: "Node.js", price: 3000 },
    { name: "Django", price: 2500 },
    { name: "PHP", price: 1500 },
    { name: "Ruby on Rails", price: 2000 },
  ],
  database: [
    { name: "MySQL", price: 1000 },
    { name: "MongoDB", price: 1200 },
    { name: "PostgreSQL", price: 1300 },
  ],
  hosting: [
    { name: "AWS", price: 2500 },
    { name: "Heroku", price: 1000 },
    { name: "DigitalOcean", price: 800 },
  ],
};

const CostPredictor = () => {
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState({
    frontend: [],
    backend: [],
    database: [],
    hosting: [],
  });

  const handleWebsiteChange = (event) => {
    const selectedType = websiteOptions.find(
      (option) => option.type === event.target.value
    );
    setSelectedWebsite(selectedType);
    setSelectedFeatures([]);
  };

  const handleFeatureChange = (event, feature) => {
    setSelectedFeatures((prev) =>
      event.target.checked
        ? [...prev, feature]
        : prev.filter((f) => f.name !== feature.name)
    );
  };

  const handleTechStackChange = (event, type, tech) => {
    setSelectedTechStack((prev) => ({
      ...prev,
      [type]: event.target.checked
        ? [...prev[type], tech]
        : prev[type].filter((t) => t.name !== tech.name),
    }));
  };

  const calculateCost = () => {
    if (!selectedWebsite) return { originalPrice: 0, discountedPrice: 0 };

    let totalCost = selectedWebsite.basePrice;

    selectedFeatures.forEach((feature) => {
      totalCost += feature.price;
    });

    Object.keys(selectedTechStack).forEach((type) => {
      selectedTechStack[type].forEach((tech) => {
        totalCost += tech.price;
      });
    });

    const discountedCost = totalCost * 0.8;
    return {
      originalPrice: totalCost,
      discountedPrice: discountedCost,
    };
  };

  const cost = calculateCost();

  return (
    <div className="cost-predictor">
      <h1 className="title">Website Development Cost Predictor</h1>

      {/* Website Selection */}
      <div className="website-selection fade-in">
        <label htmlFor="website-type">Choose Website Type:</label>
        <select id="website-type" onChange={handleWebsiteChange} defaultValue="">
          <option value="" disabled>
            Select Website Type
          </option>
          {websiteOptions.map((option, index) => (
            <option key={index} value={option.type}>
              {option.type}
            </option>
          ))}
        </select>
      </div>

      {/* Features Selection */}
      {selectedWebsite && (
        <div className="features-selection fade-in">
          <h2>Select Features:</h2>
          {allFeatures.map((feature, index) => (
            <label key={index} className="feature-option">
              <input
                type="checkbox"
                onChange={(event) => handleFeatureChange(event, feature)}
              />
              {feature.name} (+₹{feature.price})
            </label>
          ))}
        </div>
      )}

      {/* Tech Stack Selection */}
      <div className="tech-stack-selection fade-in">
        <h2>Select Your Tech Stack:</h2>
        {Object.keys(techStackOptions).map((type) => (
          <div key={type}>
            <h3>{type.charAt(0).toUpperCase() + type.slice(1)}</h3>
            {techStackOptions[type].map((tech, index) => (
              <label key={index} className="tech-option">
                <input
                  type="checkbox"
                  onChange={(event) =>
                    handleTechStackChange(event, type, tech)
                  }
                />
                {tech.name} (+₹{tech.price})
              </label>
            ))}
          </div>
        ))}
      </div>

      {/* Cost Summary */}
      <div className="cost-summary fade-in">
        <h2>Total Estimated Cost:</h2>
        <p>Original Price: ₹{cost.originalPrice}</p>
        <p>Discounted Price: ₹{cost.discountedPrice.toFixed(2)}</p>
      </div>

      <button className="contact-btn fade-in">
        <a
          href="https://wa.me/6290529857?text=I%20want%20to%20order%20a%20website"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Us on WhatsApp
        </a>
      </button>
    </div>
  );
};

export default CostPredictor;